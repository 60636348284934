import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import Template from 'components/templates/MainTemplate';
import Footer from 'components/organisms/footer/Footer';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithubAlt, faInstagram } from '@fortawesome/free-brands-svg-icons';
import ContentTitle from 'components/atoms/Text/ContentTitle';
import MainContent from 'components/molecules/container/MainContent';
import MainContainer from 'components/molecules/container/MainContainer';
import ContentWrapper from 'components/molecules/container/ContentWrapper';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 20px;
  font-weight: 600;

  p {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  a:hover {
    color: var(--point-color);
  }
`;

export default function ContactPage() {
  return (
    <Template url={`https://nxnaxx.github.io/contact`}>
      <MainContainer>
        <MainContent>
          <div style={{ marginTop: '50px' }}>
            <ContentWrapper>
              <ContentTitle icon={faPaperPlane} title={`Contact`} />
              <Content>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} />
                  nxnaxx125@gmail.com
                </p>
                <p>
                  <FontAwesomeIcon icon={faInstagram} />
                  <a href="https://www.instagram.com/raey_05/">raey_05</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faGithubAlt} />
                  <a href="https://github.com/nxnaxx">
                    https://github.com/nxnaxx
                  </a>
                </p>
              </Content>
            </ContentWrapper>
          </div>

          <Footer />
        </MainContent>
      </MainContainer>
    </Template>
  );
}
